import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';

//
import { dispatch } from '../store';

const initialState = {
   isLoading: false,
   error: null,
   users: [],
   user: null,
   currentPage: 0,
   totalPages: 0,
   totalItems: 0
};

const slice = createSlice({
   name: 'user',
   initialState,
   reducers: {
      // START LOADING
      startLoading(state) {
         state.isLoading = true;
      },

      // HAS ERROR
      hasError(state, action) {
         state.isLoading = false;
         state.error = action.payload;
      },

      getUserSuccess(state, action) {
         console.log("getUserSuccess", action.payload)
         state.isLoading = false;
         state.user = action.payload.user;
         state.currentPage = action.payload.pagination.currentPage;
         state.totalPages = action.payload.pagination.totalPages;
         state.totalItems = action.payload.pagination.totalItems;
      },

      getUsersSuccess(state, action) {
         state.isLoading = false;
         state.users = action.payload.users;
         state.currentPage = action.payload.pagination.currentPage;
         state.totalPages = action.payload.pagination.totalPages;
         state.totalItems = action.payload.pagination.totalItems;
      },
   },
});

// Reducers
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getAllUsers() {
   return async () => {
      dispatch(slice.actions.startLoading());
      try {
         const response = await axios.get('/api/users')
         console.log("getAllUsers", response.data)
         dispatch(slice.actions.getUsersSuccess(response.data));
      } catch (error) {
         dispatch(slice.actions.hasError(error));
      }
   };
}

export function getUsers(page = 1, limit = 10, filters = {}) {
   return async () => {
      dispatch(slice.actions.startLoading());
      try {
         const response = await axios.get('/api/users', {
            params: {
               page: Number(page + 1), rowsPerPage: Number(limit),
               filters
            },
         });
         dispatch(slice.actions.getUsersSuccess(response.data));
      } catch (error) {
         dispatch(slice.actions.hasError(error));
      }
   };
}

export function getUserById(id) {
   return async () => {
      dispatch(slice.actions.startLoading());
      try {
         const response = await axios.get(`/api/user/${id}`);
         dispatch(slice.actions.getUserSuccess(response.data.user));
      } catch (error) {
         dispatch(slice.actions.hasError(error));
      }
   };
}
